@use "vars" as *;
@use "sass:color";

@keyframes float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translate(0);
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotateY(0deg);
  }
  50% {
    transform: rotateY(180deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}

@keyframes fall {
  0% {
    opacity: 1;
    transform: translateY(0vh);
  }
  100% {
    opacity: 0;
    transform: translateY(100vh);
  }
}

#home {
  position: relative;
  width: 100%;
  height: 100vh;

  #top {
    display: flex;
    align-items: center;
    justify-content: space-around;
    box-sizing: border-box;
    position: relative;
    height: 30vh;
    background-image: linear-gradient(
      transparent 40%,
      #a8a8ff 40% 50%,
      #f3aeba 50% 90%,
      #a8a8ff 90% 100%
    );

    h1 {
      font-family: $heading-font;
      font-size: clamp(1.5rem, 5vw, 3rem);
      color: $base;
      text-shadow: 3px 3px 0px $blue;
      font-weight: 900;
      margin: 0;
      margin-top: 5%;
      margin-left: 10px;
      animation: float 2s ease-in-out 0s infinite normal forwards;
      flex-grow: 1;
      z-index: 3;

      @media screen and (max-width: 812px) {
        margin-top: 22%;
        position: absolute;
        left: 0;
        width: 100%;
      }
    }

    #aira {
      pointer-events: none;
      height: 100%;
      width: auto;
      overflow: hidden;

      img {
        height: 200%;
        width: auto;
        filter: drop-shadow(-10px 0px 0px $base);
      }

      @media screen and (max-width: 812px) {
        position: absolute;
        right: -30vw;
      }
    }

    @media screen and (max-width: 812px) {
      overflow: hidden;
      display: block;
    }
  }

  #bottom {
    box-sizing: border-box;

    #button-links {
      display: flex;
      gap: 3vw;
      align-items: center;
      justify-content: space-around;
      user-select: none;

      .button-container {
        font-size: 1.1rem;
        color: $blue;

        .page-desc {
          margin: auto;
          margin-top: -3vh;
          z-index: 5;
          width: 75%;
          user-select: text;

          &.left {
            text-align: left;
          }

          &.right {
            text-align: right;
          }

          @media screen and (max-width: 800px) {
            margin-top: 0;
            margin: auto;
          }
        }

        .button {
          box-sizing: border-box;
          position: relative;
          font-family: $heading-font;
          width: 25vw;
          height: 25vw;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          font-weight: 900;
          font-size: clamp(2rem, 4vw, 3rem);
          cursor: pointer;
          color: darken($color: $blue, $amount: 2%);
          text-shadow: -1px -1px 0 $base, 1px -1px 0 $base, -1px 1px 0 $base,
            1px 1px 0 $base;
          transition: transform 0.2s;
          margin: auto;

          span {
            margin-top: -3vw;

            @media screen and (max-width: 800px) {
              position: absolute;
            }
          }

          &:hover {
            transform: scale(1.1);
            .tabler-icon {
              animation: spin 1s ease-in-out 0s 1 normal forwards;
            }
          }

          &#button-rot-left {
            transform: rotate(-10deg);
          }

          &#button-rot-right {
            transform: rotate(10deg);
          }

          .tabler-icon {
            position: absolute;
            top: 0;
            left: 0;
            width: 25vw;
            height: 25vw;
            z-index: -1;
            stroke-width: 0.25;
            transition: transform 0.2s;
            stroke: lighten($color: $red, $amount: 10%);
            fill: lighten($color: $red, $amount: 20%);
            filter: drop-shadow(0px 2px 2px #00000022);

            @media screen and (max-width: 800px) {
              width: 20vh;
              height: 20vh;
            }
          }

          @media screen and (max-width: 800px) {
            margin: auto;
            width: 20vh;
            height: 20vh;
          }
        }

        @media screen and (orientation: portrait) {
          box-sizing: border-box;
          padding: 5px;
          align-items: center;
          justify-content: center;
          margin: 3vh 0px;
        }
      }

      @media screen and (orientation: portrait) {
        display: block;
      }
    }

    @media screen and (max-width: 800px) {
      overflow: hidden;
    }
  }
}
