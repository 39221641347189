@use "vars" as *;
@use "sass:color";

@import "node_modules/@tabler/icons-webfont/tabler-icons.scss";

.select-container {
  display: flex;
  flex-direction: column;
  font-size: 1vw;
  margin: 2em 0em;

  @media screen and (max-width: 810px) and (orientation: landscape) {
    font-size: 1.5vw;
  }

  @media screen and (orientation: portrait) {
    font-size: 2.1vh;
  }

  .select-label {
    position: relative;
    color: $base;

    .label-name {
      position: relative;
      z-index: 3;
      font-weight: 700;
      color: $blue;
    }
  }

  .selectbox {
    color: $text;
    margin-top: 0.5em;
    height: 2.5em;
    padding: 0.5em;
    border-radius: 50px;
    background-color: lighten($red, 22%);
    border: none;
    box-shadow: 0px 3px 0px
      lighten($color: color.adjust($color: $red, $saturation: -10), $amount: 8%);
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    cursor: pointer;

    &::-webkit-scrollbar {
      display: none;
    }

    &:after {
      content: $ti-icon-heart;
    }

    option {
      background-color: white;
      &:hover {
        cursor: pointer;
        background-color: lighten($color: $blue, $amount: 15%);
      }
    }
  }
}
