@use "vars" as *;
@use "sass:color";
@import "../../node_modules/@tabler/icons-webfont/tabler-icons.scss";

.navButton {
  font-size: 2.2vw;
  font-family: $heading-font;
  font-weight: 900;
  font-style: italic;
  padding: 0.25em 0.5em;
  color: $blue;
  text-shadow: -1px -1px 0 $base, 1px -1px 0 $base, -1px 1px 0 $base,
    1px 1px 0 $base;
  border-radius: 50px;
  border: none;
  border-bottom: 3px solid
    lighten($color: color.adjust($color: $red, $saturation: -10), $amount: 5%);
  background-color: lighten($color: $red, $amount: 15%);
  transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    background-color: lighten($color: $red, $amount: 18%);
    border-color: lighten($color: $red, $amount: 8%);
  }

  @media screen and (orientation: portrait) {
    font-size: 4vh;
  }
}

.radio-button {
  position: relative;
  font-family: $heading-font;
  font-weight: 900;
  font-style: italic;
  font-size: 2.5vw;
  flex-basis: calc(50% - 0.12em);
  height: 3em;

  input[type="radio"] {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  label {
    display: flex;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    font-size: 2em;
    width: 100%;
    height: 100%;
    background-color: lighten($color: $red, $amount: 15%);
    border-radius: 12px;
    color: $blue;
    text-shadow: -1px -1px 0 $base, 1px -1px 0 $base, -1px 1px 0 $base,
      1px 1px 0 $base;
  }

  input[type="radio"]:checked + label {
    background-color: $blue;
    color: $base;
  }

  @media screen and (orientation: portrait) {
    font-size: 4vh;
  }
}
